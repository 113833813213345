import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import './formotherhealthproblems.css';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import {ResultsContext} from '../../../../../store/results-context';
import ButtonComponent from '../../../../shared/button/Button.component';
import CheckboxComponent from '../../../../shared/checkbox/Checkbox.component';


interface FormOtherHealthProblemsProps {
    t: (key: string) => string;
}

const FormOtherHealthProblems: React.FC<FormOtherHealthProblemsProps> = ({t}) => {

    const navigate = useNavigate();
    const resultsCtx = useContext(ResultsContext);

    const goNextButtonText = t('go_next_button');
    const goBackButtonText = t('go_back_button');


    const goBackButtonHandler = () => {
        navigate('/civilization-diseases');
    }

    const goNextButtonHandler = () => {
        navigate('/');
    }

    return (

        <div className="form-civilization-diseases__wrapper">


            <div className="civilization-diseases__inner-wrapper">

                <div className="civilization-diseases_label-input__wrapper">

                    <div className="civilization-diseases_header__wrapper">
                        {t('screen_other_health_problems')}
                    </div>


                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="neoplasticDisease"
                            light={true}
                            value="neoplasticDisease"
                            textValue={t('neoplastic_disease')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onNeoplasticDisease(value);
                            }}
                            checked={resultsCtx.neoplasticDisease}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="immuneDisorders"
                            light={true}
                            value="immuneDisorders"
                            textValue={t('immune_disorders')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onImmuneDisorders(value);
                            }}
                            checked={resultsCtx.immuneDisorders}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="usedMedications"
                            light={true}
                            value="usedMedications"
                            textValue={t('used_medications')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onUsedMedications(value);
                            }}
                            checked={resultsCtx.usedMedications}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="hivInfection"
                            light={true}
                            value="hivInfection"
                            textValue={t('hiv_infection')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onHivInfection(value);
                            }}
                            checked={resultsCtx.hivInfection}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="boneMarrowTransplantation"
                            light={true}
                            value="boneMarrowTransplantation"
                            textValue={t('bone_marrow_transplantation')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onBoneMarrowTransplantation(value);
                            }}
                            checked={false}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="boneMarrowTransplantationBefore"
                            light={true}
                            value="boneMarrowTransplantationBefore"
                            textValue={t('bone_marrow_transplantation_before')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onBoneMarrowTransplantation(value);
                            }}
                            checked={resultsCtx.boneMarrowTransplantation}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="boneMarrowTransplantationAfter"
                            light={true}
                            value="boneMarrowTransplantationAfter"
                            textValue={t('bone_marrow_transplantation_after')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onBoneMarrowTransplantation(value);
                            }}
                            checked={resultsCtx.boneMarrowTransplantation}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="organTransplantation"
                            light={true}
                            value="organTransplantation"
                            textValue={t('organ_transplantation')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onOrganTransplantation(value);
                            }}
                            checked={false}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="organTransplantationBefore"
                            light={true}
                            value="organTransplantationBefore"
                            textValue={t('organ_transplantation_before')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onOrganTransplantation(value);
                            }}
                            checked={resultsCtx.organTransplantation}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="organTransplantationAfter"
                            light={true}
                            value="organTransplantationAfter"
                            textValue={t('organ_transplantation_after')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onOrganTransplantation(value);
                            }}
                            checked={resultsCtx.organTransplantation}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="cochlearImplantation"
                            light={true}
                            value="cochlearImplantation"
                            textValue={t('cochlear_implantation')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onCochlearImplantation(value);
                            }}
                            checked={resultsCtx.cochlearImplantation}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="cerebroSpinalFluidLeak"
                            light={true}
                            value="cerebroSpinalFluidLeak"
                            textValue={t('cerebro_spinal_fluid_leak')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onCerebroSpinalFluidLeak(value);
                            }}
                            checked={resultsCtx.cerebroSpinalFluidLeak}
                        />
                    </div>

                </div>


                <div className="navigation-buttons__wrapper">

                    <ButtonComponent id="goBackButton"
                                     value={goBackButtonText}
                                     className="navigation-button"
                                     onClick={goBackButtonHandler}/>

                    <ButtonComponent id="goNextButton"
                                     value={goNextButtonText}
                                     className="navigation-button"
                                     onClick={goNextButtonHandler}/>


                </div>

            </div>
        </div>
    )
}

export default FormOtherHealthProblems;
