import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import './formbirthdate.css';
import {ConfigProvider, DatePicker} from 'antd';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import {Locale} from 'antd/es/locale';
import SwitchComponent from '../../../../shared/switch/Switch.component';
import {ResultsContext} from '../../../../../store/results-context';
import ButtonComponent from '../../../../shared/button/Button.component';
import dayjs from 'dayjs';


interface FormBirthDateProps {
    t: (key: string) => string;
    currentLocale: Locale;
}

const FormBirthDate: React.FC<FormBirthDateProps> = ({t, currentLocale}) => {

    const navigate = useNavigate();

    const resultsCtx = useContext(ResultsContext);

    const goNextButtonText = t('go_next_button');
    const goBackButtonText = t('go_back_button');

    const goBackButtonHandler = () => {
        navigate('/');
    }

    const goNextButtonHandler = () => {
        navigate('/civilization-diseases');
    }

    return (

        <div className="form-birth-date__wrapper">

            <div className="label-input__wrapper">
                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_birth_date')}
                    </div>
                    <div className="input__wrapper">
                        <ConfigProvider
                            theme={{ token: { colorPrimary: '#bdbdbd', } }}
                            locale={currentLocale}>
                            <DatePicker
                                size={'large'}
                                value={resultsCtx.dateOfBirth ? dayjs(resultsCtx.dateOfBirth) : null}
                                onChange={(date, dateString) => resultsCtx.onDateOfBirth(date ? date.toDate() : null)}
                                format="YYYY-MM-DD"
                                allowClear={false}
                                showToday={false}
                                inputReadOnly={true}
                            />
                        </ConfigProvider>
                    </div>


                </div>
                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_pregnancy')}
                    </div>
                    <div className="input__wrapper">
                        <SwitchComponent id="pregnancy"
                                         onCheckHandler={resultsCtx.onPregnancyClick}
                                         checked={resultsCtx.pregnancy}/>
                    </div>
                </div>
                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_planned_pregnancy')}
                    </div>
                    <div className="input__wrapper">
                        <SwitchComponent id="plannedPregnancy"
                                         onCheckHandler={resultsCtx.onPlannedPregnancyClick}
                                         checked={resultsCtx.plannedPregnancy}/>
                    </div>
                </div>

                <div className="navigation-buttons__wrapper">

                    <ButtonComponent id="goBackButton"
                                     value={goBackButtonText}
                                     className="navigation-button"
                                     onClick={goBackButtonHandler}/>

                    <ButtonComponent id="goNextButton"
                                     value={goNextButtonText}
                                     className="navigation-button"
                                     onClick={goNextButtonHandler}/>


                </div>


            </div>
        </div>
    )
}

export default FormBirthDate;
