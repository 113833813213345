import React from 'react';
import {ResultsModel} from '../model/results.model';


export const ResultsContext = React.createContext<ResultsModel>({
    userId: '',
    profession: '',
    onProfessionChange: (value: string) => {},
    agreeToTerms: false,
    onAgreeToTermsChange: (value: boolean) => {},
    licenseNumber: '',
    onLicenseNumberChange: (value: string) => {},
    dateOfBirth: null,
    onDateOfBirth: (value: Date | null) => {},
    pregnancy: false,
    onPregnancyClick: (value: boolean) => {},
    plannedPregnancy: false,
    onPlannedPregnancyClick: (value: boolean) => {},
    circulatorySystemDisease: false,
    onCirculatorySystemDisease: (value: boolean) => {},
    diabetes: false,
    onDiabetes: (value: boolean) => {},
    chronicRespiratoryDisease: false,
    onChronicRespiratoryDisease: (value: boolean) => {},
    chronicKidneyDisease: false,
    onChronicKidneyDisease: (value: boolean) => {},
    chronicLiverDisease: false,
    onChronicLiverDisease: (value: boolean) => {},
    viralHepatitisC: false,
    onViralHepatitisC: (value: boolean) => {},
    depression: false,
    onDepression: (value: boolean) => {},
    neoplasticDisease: false,
    onNeoplasticDisease: (value: boolean) => {},
    spleenDysfunction: false,
    onSpleenDysfunction: (value: boolean) => {},
    immuneDisorders: false,
    onImmuneDisorders: (value: boolean) => {},
    usedMedications: false,
    onUsedMedications: (value: boolean) => {},
    hivInfection: false,
    onHivInfection: (value: boolean) => {},
    boneMarrowTransplantation: false,
    onBoneMarrowTransplantation: (value: boolean) => {},
    organTransplantation: false,
    onOrganTransplantation: (value: boolean) => {},
    cochlearImplantation: false,
    onCochlearImplantation: (value: boolean) => {},
    cerebroSpinalFluidLeak: false,
    onCerebroSpinalFluidLeak: (value: boolean) => {},
});
