import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import './i18n';
import { Route, Routes } from 'react-router-dom';
import MainScreen from './components/screens/main-screen/MainScreen';
import FormBirthDate from './components/screens/subscreens/questionnaire/form-birth-date/FormBirthDate';
import {useTranslation} from 'react-i18next';
import QuestionnaireChoice from './components/screens/subscreens/questionnaire-choice/QuestionnaireChoice';
import QandA from './components/screens/subscreens/q-and-a/QandA';
import News from './components/screens/subscreens/news/News';
import Brochures from './components/screens/subscreens/brochures/Brochures';
import About from './components/screens/subscreens/about/About';
import Login from './components/screens/subscreens/questionnaire/login/Login';
import {ResultsContext} from './store/results-context';
import {ResultsModel} from './model/results.model';
import {v4 as uuidv4} from 'uuid';
import {Profession} from './components/screens/subscreens/questionnaire/login/Profession';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import plPL from 'antd/lib/locale/pl_PL';
import {Locale} from 'antd/es/locale';
import FormCivilizationDiseases
    from './components/screens/subscreens/questionnaire/form-civilization-diseases/FormCivilizationDiseases';
import FormOtherHealthProblems
    from './components/screens/subscreens/questionnaire/form-other-health-problems/FormOtherHealthProblems';

function App() {

    const {t, i18n} = useTranslation();

    const [locale, setLocale] = useState('pl');
    const [currentLocale, setCurrentLocale] = useState(plPL);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language).then(() => {})
    }

    const changeLocale = (currentLocale: Locale) => {
        setLocale(currentLocale.locale)
        setCurrentLocale(currentLocale);
        dayjs.locale(locale === 'pl' ? 'pl' : 'en');
    }


    const [userId, setUserId] = useState<string>(uuidv4());
    const userIdCached = useMemo(() => ({userId, setUserId}), [userId, setUserId]);
    const [profession, setProfession] = useState<string>(Profession.doctor);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
    const [licenseNumber, setLicenseNumber] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [pregnancy, setPregnancy] = useState<boolean>(false);
    const [plannedPregnancy, setPlannedPregnancy] = useState<boolean>(false);
    const [circulatorySystemDisease, setCirculatorySystemDisease] = useState<boolean>(false);
    const [diabetes, setDiabetes] = useState<boolean>(false);
    const [chronicRespiratoryDisease, setChronicRespiratoryDisease] = useState<boolean>(false);
    const [chronicKidneyDisease, setChronicKidneyDisease] = useState<boolean>(false);
    const [chronicLiverDisease, setChronicLiverDisease] = useState<boolean>(false);
    const [viralHepatitisC, setViralHepatitisC] = useState<boolean>(false);
    const [depression, setDepression] = useState<boolean>(false);
    const [neoplasticDisease, setNeoplasticDisease] = useState<boolean>(false);
    const [spleenDysfunction, setSpleenDysfunction] = useState<boolean>(false);
    const [immuneDisorders, setImmuneDisorders] = useState<boolean>(false);
    const [usedMedications, setUsedMedications] = useState<boolean>(false);
    const [hivInfection, setHivInfection] = useState<boolean>(false);
    const [boneMarrowTransplantation, setBoneMarrowTransplantation] = useState<boolean>(false);
    const [organTransplantation, setOrganTransplantation] = useState<boolean>(false);
    const [cochlearImplantation, setCochlearImplantation] = useState<boolean>(false);
    const [cerebroSpinalFluidLeak, setCerebroSpinalFluidLeak] = useState<boolean>(false);


    const licenceNumberChangeHandler = (value: string) => {
        setLicenseNumber(value);
    }

    const dateOfBirthHandler = (value: Date | null) => {
        setDateOfBirth(value);
    };

    const pregnancyHandler = (value: boolean) => {
        setPregnancy(value);
    }

    const plannedPregnancyHandler = (value: boolean) => {
        setPlannedPregnancy(value);
    }

    const circulatorySystemDiseaseHandler = (value: boolean) => {
        setCirculatorySystemDisease(value);
    }

    const diabetesHandler = (value: boolean) => {
        setDiabetes(value);
    }

    const chronicRespiratoryDiseaseHandler = (value: boolean) => {
        setChronicRespiratoryDisease(value);
    }

    const chronicKidneyDiseaseHandler = (value: boolean) => {
        setChronicKidneyDisease(value);
    }

    const chronicLiverDiseaseHandler = (value: boolean) => {
        setChronicLiverDisease(value);
    }

    const viralHepatitisCHandler = (value: boolean) => {
        setViralHepatitisC(value);
    }

    const depressionHandler = (value: boolean) => {
        setDepression(value);
    }

    const neoplasticDiseaseHandler = (value: boolean) => {
        setNeoplasticDisease(value);
    }

    const spleenDysfunctionHandler = (value: boolean) => {
        setSpleenDysfunction(value);
    }

    const immuneDisordersHandler = (value: boolean) => {
        setImmuneDisorders(value);
    }

    const usedMedicationsHandler = (value: boolean) => {
        setUsedMedications(value);
    }

    const hivInfectionHandler = (value: boolean) => {
        setHivInfection(value);
    }

    const boneMarrowTransplantationHandler = (value: boolean) => {
        setBoneMarrowTransplantation(value);
    }

    const organTransplantationHandler = (value: boolean) => {
        setOrganTransplantation(value);
    }

    const cochlearImplantationHandler = (value: boolean) => {
        setCochlearImplantation(value);
    }

    const cerebroSpinalFluidLeakHandler = (value: boolean) => {
        setCerebroSpinalFluidLeak(value);
    }

    const professionChangeHandler = (value: string) => {
        setProfession(value);
    }

    const agreeToTermsChangeHandler = (state: boolean) => {
        setAgreeToTerms(state);
    }

    useEffect(() => {}, [
        licenseNumber,
        agreeToTerms,
        profession,
        pregnancy,
        plannedPregnancy
    ]);

    const resultsCtx: ResultsModel = {
        userId: userIdCached.userId,
        profession: profession,
        onProfessionChange: professionChangeHandler,
        agreeToTerms: agreeToTerms,
        onAgreeToTermsChange: agreeToTermsChangeHandler,
        licenseNumber: licenseNumber,
        onLicenseNumberChange: licenceNumberChangeHandler,
        dateOfBirth: dateOfBirth,
        onDateOfBirth: dateOfBirthHandler,
        pregnancy: pregnancy,
        onPregnancyClick: pregnancyHandler,
        plannedPregnancy: plannedPregnancy,
        onPlannedPregnancyClick: plannedPregnancyHandler,
        circulatorySystemDisease: circulatorySystemDisease,
        onCirculatorySystemDisease: circulatorySystemDiseaseHandler,
        diabetes: diabetes,
        onDiabetes: diabetesHandler,
        chronicRespiratoryDisease: chronicRespiratoryDisease,
        onChronicRespiratoryDisease: chronicRespiratoryDiseaseHandler,
        chronicKidneyDisease: chronicKidneyDisease,
        onChronicKidneyDisease: chronicKidneyDiseaseHandler,
        chronicLiverDisease: chronicLiverDisease,
        onChronicLiverDisease: chronicLiverDiseaseHandler,
        viralHepatitisC: viralHepatitisC,
        onViralHepatitisC: viralHepatitisCHandler,
        depression: depression,
        onDepression: depressionHandler,
        neoplasticDisease: neoplasticDisease,
        onNeoplasticDisease: neoplasticDiseaseHandler,
        spleenDysfunction: spleenDysfunction,
        onSpleenDysfunction: spleenDysfunctionHandler,
        immuneDisorders: immuneDisorders,
        onImmuneDisorders: immuneDisordersHandler,
        usedMedications: usedMedications,
        onUsedMedications: usedMedicationsHandler,
        hivInfection: hivInfection,
        onHivInfection: hivInfectionHandler,
        boneMarrowTransplantation: boneMarrowTransplantation,
        onBoneMarrowTransplantation: boneMarrowTransplantationHandler,
        organTransplantation: organTransplantation,
        onOrganTransplantation: organTransplantationHandler,
        cochlearImplantation: cochlearImplantation,
        onCochlearImplantation: cochlearImplantationHandler,
        cerebroSpinalFluidLeak: cerebroSpinalFluidLeak,
        onCerebroSpinalFluidLeak: cerebroSpinalFluidLeakHandler
    };

    return (
        <ResultsContext.Provider value={resultsCtx}>
            <Routes>
                <Route path="/" element={<MainScreen t={t} changeLanguage={changeLanguage} changeCurrentLocale={changeLocale} />}>
                    <Route index element={<QuestionnaireChoice t={t} />} />
                    <Route path="professionals" element={<Login t={t} />} />
                    <Route path="patients" element={<FormBirthDate t={t} currentLocale={currentLocale} />} />
                    <Route path="civilization-diseases" element={<FormCivilizationDiseases t={t} />} />
                    <Route path="other-health-problems" element={<FormOtherHealthProblems t={t} />} />
                    <Route path="about" element={<About t={t} />} />
                    <Route path="brochures" element={<Brochures t={t} />} />
                    <Route path="qanda" element={<QandA t={t} />} />
                    <Route path="news" element={<News t={t} />} />
                </Route>
            </Routes>
        </ResultsContext.Provider>
    );
}

export default App;
